import React from 'react';
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from '../../components/SimpleEntryGallery/SimpleEntryGallery';
import { useTurn2meInfo } from '../../content/projects/turn2me.project';
import { PageComponent } from '../../types/gatsby.types';
// import './ProjectPageTurn2me.scss';

type ProjectPageTurn2meProps = {}

const ProjectPageTurn2me: PageComponent<ProjectPageTurn2meProps> = props => {
  const metaInfo = useTurn2meInfo();
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
  >
    <SimpleEntryGallery entry={metaInfo} autoAddMobileSlides />
  </ProjectPage>
}

export default ProjectPageTurn2me;